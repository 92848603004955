import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IntroComponent } from './pages/intro/intro.component';


const routes: Routes = [
   { path: '', component: IntroComponent, pathMatch: 'full' },
  // { path: 'rates', redirectTo: '/rates/1/თმის შეჭრა', pathMatch: 'full' },
  // { path: 'rates', component: RatesComponent },
  // { path: 'rates/:id/:name', component: RatesComponent },
  // { path: 'faq', component: FaqsComponent },
  // { path: 'faq/:id', component: FaqDetailsComponent },
  // { path: 'contacts', component: ContactsComponent },
  // { path: 'blog/:id', component: BlogDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
