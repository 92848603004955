import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'ex-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit, AfterViewInit {

  videoUrls: string[] = [
    'https://dm0qx8t0i9gc9.cloudfront.net/watermarks/video/HUJnT00Qbj4eiygwy/videoblocks-hairdresser-is-brushing-womans-long-coloured-hair-in-hair-salon-with-hair-dryer-and-hairbrush-woman-with-wet-clean-hair-dries-hair-closeup-profile-footage-slow-mo_bk7nhri_m__55e0a7a0158f8146a7897d5454f79028__P360.mp4',
    'https://dm0qx8t0i9gc9.cloudfront.net/watermarks/video/HUJnT00Qbj4eiygwy/videoblocks-happy-young-woman-plays-with-her-hair-in-hair-salon-looks-in-camera-smiles-and-laughs-turns-around-and-tosses-her-long-colourful-hair-shows-a-big-thumb-up-to-camera-and-smiles-portrait-slo__f788b852574160d2e4dc61ff3ad0e7a0__P360.mp4',
    'https://dm0qx8t0i9gc9.cloudfront.net/watermarks/video/HUJnT00Qbj4eiygwy/videoblocks-beautiful-woman-with-long-blond-hair-is-sitting-in-hair-salon-while-hairdresser-is-colouring-her-hair-above-the-sink-hairdresser-in-professional-gloves-in-working-process-close-up-slow-mo___d7b95f609deba235918112f719fe7f6b__P360.mp4',
    'https://dm0qx8t0i9gc9.cloudfront.net/watermarks/video/Vd3bj2jPe/videoblocks-man-at-hair-salon-skillful-hairdresser-cutting-male-hair-on-beard-barber-cutting-mans-beard-with-clipper-hairdresser-working-with-hair-clipper-grooming-beard-in-barbershop_bbzzuealr__9a3e0aa54c25f2b3a77ce83bdc8e35e4__P360.mp4',
    'https://dm0qx8t0i9gc9.cloudfront.net/watermarks/video/V99PvIRsxil98uknx/videoblocks-hairdresser-doing-hair-cut-with-hair-machine-and-comb-in-barber-shop-stylist-hairdressing-bearded-man-with-trimmer-and-hairbrush-close-up-male-hairstyle-with-electrical-shaver-in-hair-salon_rfoxaxowp__cf116015a6a40c4b3d1c028c7c87866c__P360.mp4'
  ];
  urlToPlay: string = 'https://dm0qx8t0i9gc9.cloudfront.net/watermarks/video/HUJnT00Qbj4eiygwy/videoblocks-hairdresser-is-brushing-womans-long-coloured-hair-in-hair-salon-with-hair-dryer-and-hairbrush-woman-with-wet-clean-hair-dries-hair-closeup-profile-footage-slow-mo_bk7nhri_m__55e0a7a0158f8146a7897d5454f79028__P360.mp4';
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  constructor() { }
  ngOnInit(): void {
    this.urlToPlay = this.random();
  }


  random() {

    return this.videoUrls[Math.floor(Math.random() * this.videoUrls.length)];

  }

  ngAfterViewInit() {
    this.videoplayer.nativeElement.muted = true;
    this.videoplayer.nativeElement.play();
  }

}
