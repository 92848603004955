<!-- <video width="100%"
       controls=""
       controlslist="nodownload"
       muted="1"
       loop="1"
       autoplay="1"
       preload="auto">
        <source src="https://dm0qx8t0i9gc9.cloudfront.net/watermarks/video/HUJnT00Qbj4eiygwy/videoblocks-happy-young-woman-plays-with-her-hair-in-hair-salon-looks-in-camera-smiles-and-laughs-turns-around-and-tosses-her-long-colourful-hair-shows-a-big-thumb-up-to-camera-and-smiles-portrait-slo__f788b852574160d2e4dc61ff3ad0e7a0__P360.mp4"
                type="video/mp4"></video> -->
<!-- <source [src]="urlToPlay" -->
<!-- <video width="100%"
       muted="muted"
       loop
       #videoPlayer>

        <source [src]="urlToPlay"
                type="video/mp4">
</video> -->
<div style="position: fixed; z-index: -99; width: 100%; height: 100%">
<iframe frameborder="0"
        height="100%"
        width="100%"
        allow="autoplay"
        src="https://youtube.com/embed/mJMkhN79jZY?autoplay=1&controls=0&showinfo=0&autohide=1&loop=1&mute=1">
</iframe>
</div>
<!-- https://youtu.be/mJMkhN79jZY -->

<div class="content">
        <h1>Salon EXCLUSIVE</h1>
        <p>Comming Soon...</p>

</div>



<!--
<iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FMyExclusiveSalon%2Fposts%2F2654514114793601&width=500"
        width="500"
        height="268"
        style="border:none;overflow:hidden;display: block;margin: 20px auto;"
        scrolling="no"
        frameborder="0"
        allowTransparency="true"
        allow="encrypted-media"></iframe>


<iframe src="https://poll.app.do/embed/2872884"
        height="720"
        loading="lazy"
        style="display:block;width:100%;max-width:810px;margin:auto;border:3px solid #444444;border-radius:6px;"></iframe>







<script src='https://poll.app.do/iframe/2872884.js'></script>
-->