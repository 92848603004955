<header class="main-header">

    <div class="top-nav">
        <div class="nav-wrapper">
            <ul class="top-menu social f-left">
                <li>
                    <a href="https://www.facebook.com/MyExclusiveSalon/"
                       target="_blank">
                        <i class="fa fa-facebook"
                           aria-hidden="true"></i>
                    </a>
                </li>
            </ul>

            <ul class="top-menu f-right">
                <li>ორ – კვ: 9.30–20.00</li>
                <li>
                    <a href="https://www.facebook.com/MyExclusiveSalon/"
                       target="_blank">ვზრუნავთ თქვენ სილამაზეზე</a>
                </li>
                <!-- <li><a href="">დაჯავშნა</a></li> -->
            </ul>
        </div>

    </div>


    <div class="main-nav">
        <div class="menu-wrapper">
            <a href="/"
               class="logo">
                <img src="assets/images/logo.png" />
            </a>

            <ul class="horizontal-menu">
                <li>
                    <a routerLink="/"
                       routerLinkActive="active"
                       [routerLinkActiveOptions]="{exact:true}">მთავარი</a>
                </li>
                <li>
                    <a href="https://shop.myexclusive.ge"
                       target="_blank">შოპინგი</a>
                </li>
                <li>
                    <a routerLink="/rates"
                       routerLinkActive="active">ტარიფები</a>
                </li>
                <!-- <li><a href="/rates">ტარიფები</a></li> -->
                <!-- <li>
                    <a routerLink="/faq"
                       routerLinkActive="active">ხშირად დასმული კითხვები</a>
                </li> -->
                <li>
                    <a routerLink="/contact"
                       routerLinkActive="active">კონტაქტი</a>
                </li>
            </ul>
        </div>
    </div>
</header>