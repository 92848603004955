import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactModule } from './pages/contact/contact.module';
import { CoreModule } from './core/core.module';
import { RatesModule } from './pages/rates/rates.module';
import { IntroComponent } from './pages/intro/intro.component';

@NgModule({
  declarations: [
    AppComponent,
    IntroComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ContactModule,
    CoreModule,
    RatesModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
