<div class="content">
    <div class="page-title">კონტაქტი</div>
    <div class="contacts">
        <div class="contact-cullomn">
            <p class="icon"></p>
            <p class="text">
                ქვემო ქართლი, რუსთავი, <br> 20 მიკრორაიონი #5
            </p>
        </div>
        <div class="contact-cullomn">
            <p class="icon"></p>
            <p class="text">Tel:(0341) 27 26 01 <br> E'mail: <a
                   href="mailto:info@myexlcusive.ge">info@myexlcusive.ge</a> </p>
        </div>
        <div class="contact-cullomn">
            <p class="icon"></p>
            <p class="text">ორ – კვ: 9.30–20.00</p>
        </div>
    </div>
</div>