import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RatesRoutingModule } from './rates-routing.module';
import { RateComponent } from './rate/rate.component';


@NgModule({
  declarations: [RateComponent],
  imports: [
    CommonModule,
    RatesRoutingModule
  ]
})
export class RatesModule { }
